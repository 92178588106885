import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import VueFire from 'vuefire';
import VeeValidate from 'vee-validate';
import App from './App.vue';
import router from './router';
import store from './store';
import './filters';
import './firebase';

// VueAnalytics dependency
Vue.use(VueAnalytics, {
  id: 'UA-128207219-1',
  router,
});

// VueFire dependency
Vue.use(VueFire);

// VeeValidate dependency
Vue.use(VeeValidate, {
  classes: true,
  classNames: {
    valid: 'is-valid',
    invalid: 'is-invalid',
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
