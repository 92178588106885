<template>
  <section>
    <a href="https://www.instagram.com/freewaymotorsok" target="_blank">
      <img class="instagram-icon" src="@/assets/instagram-icon.png" alt="Instagram logo">
    </a>
  </section>
</template>

<script>
export default {
  name: 'Instagram',
};
</script>

<style scoped lang="scss">
.instagram-icon {
  max-width: 60px;
  cursor: pointer;
  position: fixed;
  right: 15px;
  bottom: 95px;
  z-index: 1;
}
</style>
