<template>
  <section class="new-location">
    <p>
      <a href="https://goo.gl/maps/oJ93bFo2RVnB8jX27" target="_blank">Nos mudamos a una cuadra, estamos en Avenida del Libertador 6430</a>
    </p>
  </section>
</template>

<script>
export default {
  name: 'NewLocation',
};
</script>

<!-- add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.new-location {
  box-sizing: border-box;
  background: linear-gradient(180deg, #e93508, #cc1e1e);
  color: white;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: fixed;
  z-index: 1;
}
</style>
