<template>
  <section class="general-container">
    <p>Somos una empresa Argentina seria y eficiente con más de veinte años en el mercado dedicándonos a la venta de repuestos y accesorios originales para Mercedes Benz y BMW brindándole seguridad, calidad y los mejores precios. Ponemos a su disposición las mejores marcas líderes en el mercado actual y con el más variado stock. Somos importadores y exportadores directos de Alemania.</p>
    <article class="services">
      <ul>
        <li>Ventas al por mayor y menor</li>
        <li>Atención a compañías de seguros</li>
        <li>Presupuestos sin cargo</li>
      </ul>
      <ul>
        <li>Descuentos al gremio</li>
        <li>Respuestas en menos de 24hs</li>
        <li>Envíos al interior, Capital y GBA</li>
      </ul>
      <ul>
        <li>Entregas por moto a Capital/GBA</li>
        <li>Entregas por micro al interior</li>
        <li>Estacionamiento gratuito</li>
      </ul>
    </article>
    <article class="products">
      <div class="products-image"></div>
      <div class="products-information-container">
        <h2 class="dark-background">Productos</h2>
        <p>Contamos con la más amplia variedad y las mejores marcas en repuestos y accesorios originales para todos los modelos de Mercedes Benz y BMW</p>
        <p>Filtros de aceite - Filtros de aire - Filtros de combustible - Ópticas - Suspensiones - Juntas - Correas - Bujías - Amortiguadores - Discos de freno - Pastillas de freno - Tren delantero - Tren trasero y muchos otros productos más</p>
        <a href="http://www.routeparts.com.ar" target="_blank">Venta de autopoartes nacionales en tren delantero y suspensión</a>
      </div>
    </article>
    <article class="brands-slider">
      <div class="brands-slider-container">
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: 'Empresa',
  beforeCreate() {
    document.body.className = 'company';
  },
};
</script>

<style lang="scss" scoped>
.general-container {
  padding: 0 1.25rem;
  color: #F4F4F4;

  p {
    line-height: 1.5;
  }

  .services {
    text-align: center;
    padding-left: 2em;
    margin-top: 3rem;

    @include grid-media($tablet-neat-grid) {
      padding-left: 0;
    }

    ul {
      display: block;
      max-width: 18rem;
      font-size: 1.1em;
      text-align: left;
      padding: 0;
      margin: 0 auto;

      @include grid-media($big-desktop-neat-grid) {
        font-size: 1.125rem;
        display: inline-block;
        padding: 1rem 1rem 0;
        margin: 0 1rem;
      }

      li {
        display: list-item;
        list-style-type: circle;
        padding: .4rem;
      }
    }
  }

  .products {
    display: flex;
    flex-direction: column;
    margin: 4rem 0;

    @include grid-media($desktop-neat-grid) {
      flex-direction: row;
    }

    .products-image {
      background: url('../assets/products.jpg') center center no-repeat;
      width: 100%;
      height: 10rem;

      @include grid-media($desktop-neat-grid) {
        width: 50%;
        height: 40rem;
        background-position: right bottom;
      }
    }

    .products-information-container {
      width: 100%;
      color: #FFF;
      position: relative;
      padding: 0;

      @include grid-media($desktop-neat-grid) {
        width: 50%;
        padding: 0 4rem;
      }

      p {
        margin-bottom: 2rem;
      }

      a {
        font-size: 20px;
      }
    }
  }

  .brands-slider {
    padding: 1rem;
    overflow: hidden;

    .brands-slider-container {
      background: url('../assets/brand-logos.jpg') repeat-x;
      height: 135px;
      width: 9000px; // 9000 is the width of the image (3000) multiplied by 3
      animation: brandSlider 20s linear infinite;
    }
  }

  @keyframes brandSlider {
    0% {
      transform: translate3d(0, 0, 0);
    }
    
    100% {
      transform: translate3d(-3000px, 0, 0); // 3000 is the width of the image
    }
  }
}
</style>

