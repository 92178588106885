import Vue from 'vue';

// convert strings to uppercase
Vue.filter('uppercase', (stringValue) => {
  if (!stringValue) {
    return '';
  } else if (typeof stringValue === 'string') {
    return stringValue.toUpperCase();
  }
});
