<template>
  <section id="app">
    <NewLocation />
    <Nav />
    <Hero-Unit />
    <router-view/>
    <Instagram/>
    <WhatsApp/>
    <Footer/>
  </section>
</template>

<script>
// @ is an alias to /src
import NewLocation from '@/components/new-location/NewLocation.vue';
import Nav from '@/components/nav/Nav.vue';
import HeroUnit from '@/components/hero-unit/Hero-Unit.vue';
import Instagram from '@/components/instagram/Instagram.vue';
import WhatsApp from '@/components/whatsapp/WhatsApp.vue';
import Footer from '@/components/footer/Footer.vue';

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Freeway Motors - Repuestos originales para Mercedes Benz y BMW',
    meta: [
      // Short description of the document (limit to 150 characters)
      { vmid: 'description', name: 'description', content: 'Venta de repuestos Alemanes importados originales para Mercedes Benz y BMW - Teléfono: 011 4787-2626' },
      // Control the behavior of search engine crawling and indexing
      { name: 'robots', content: 'index,follow' },
    ],
    links: [
      // instruct search engines to consider this URL as intended
      { rel: 'canonical', href: 'http://www.freewaymotors.com.ar' },
    ],
  },
  components: {
    NewLocation,
    Nav,
    HeroUnit,
    Instagram,
    WhatsApp,
    Footer,
  },
};
</script>

<!-- don't add 'scoped' here, if not <html> and <body> elements won't apply SCSS -->
<style lang="scss">
// import global SCSS that will apply to all child components
@import './scss/app';
</style>
