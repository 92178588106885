import Firebase from 'firebase';

const firebaseApp = Firebase.initializeApp({
  // Firebase configuration data
  projectId: 'freeway-motors',
  databaseURL: 'https://freeway-motors.firebaseio.com',
});

// export the database for components to use
export const db = firebaseApp.database();
