<template>
  <header class="hero-unit">
    <div class="hero-unit__information">
      <h1>{{ companyName | uppercase }}</h1>
      <h2>{{ companyDescription }} <a class="budget-cta" @click="clickBudgetCTA">Pedir presupuesto</a></h2>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Hero-Unit',
  computed: {
    ...mapState([
      'companyName',
      'companyDescription',
    ])
  },
  methods: {
    clickBudgetCTA() {
      this.$router.push({
        path: '/contacto'
      });
    }
  },
};
</script>

<!-- add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hero-unit {
  background: url('../../assets/mercedes-benz-hero-unit-background.jpg') center center no-repeat;
  background-size: cover;
	height: 30em;
  transition: height 400ms linear;
  position: relative;

  @include grid-media($tablet-neat-grid) {
    height: 25em;
  }

  @include grid-media($desktop-neat-grid) {
    height: 37em;
  }

  .hero-unit__information {
    max-width: 36em;
    text-align: center;
    padding: 0 1.25em;
    position: absolute;
    bottom: -5rem;
    transition: bottom .3s linear;

    @include grid-media($tablet-neat-grid) {
      text-align: left;
      bottom: 2em;
    }

    h1 {
      background: white;
      display: block;
      padding: 1em 1.25em;
      margin: 0 0 1.25em 0;

      @include grid-media($tablet-neat-grid) {
        display: inline-block;
      }
    }

    h2 {
      background: linear-gradient(180deg, $dark-blue, $darker-blue);
      color: white;
      line-height: 1.2;
      display: inline-block;
      padding: .625em 0.2em;

      @include grid-media($tablet-neat-grid) {
        padding: 0.625em 1.25em;
      }

      .budget-cta {
        display: block;
        width: 250px;
        background-color: #20BC7E;
        font-size: 1.1rem;
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
        color: #242b3a;
        cursor: pointer;
        border-radius: 20px;
        border: 1px solid #20BC7E;
        padding: 10px;
        margin: 15px auto 10px;

        @include grid-media($tablet-neat-grid) {
          margin: 20px auto 10px;
        }

        &:hover {
          background-color: #242b3a;
          color: white;
        }
      }
    }
  }
}
</style>
