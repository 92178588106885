<template>
  <footer>
    <div class="logos-container">
      <img src="@/assets/bmw-logo.png" alt="Logo BMW" />
      <img src="@/assets/mercedes-benz-logo.png" alt="Logo Mercedes Benz" />
    </div>
    <a class="afip-qrcode" href="http://qr.afip.gob.ar/?qr=co3q_dW2lDz7zn-vLvIHEQ,," target="_F960AFIPInfo">
      <img src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0" />
    </a>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid white;
  width: 90%;
  padding: 1rem 0;
  margin: 0 auto;

  .logos-container img {
    max-width: 25%;
    margin-right: 20px;

    @include grid-media($tablet-neat-grid) {
      max-width: 10%;
    }
  }

  .afip-qrcode {
    text-align: right;
    
    img {
      max-width: 100%;

      @include grid-media($tablet-neat-grid) {
        max-width: 30%;
      }
    }
  }
}
</style>
