<template>
  <section>
    <a href="https://api.whatsapp.com/send?phone=5491162241258&text=Me%20gustaría%20que%20me%20pasen%20un%20presupuesto%20de%20los%20siguientes%20productos (incluir número de chasis y número de motor):%20" target="_blank">
      <img class="whatsapp-icon" src="@/assets/whatsapp-icon.png" alt="Presupuestos por WhatsApp">
    </a>
  </section>
</template>

<script>
export default {
  name: 'WhatsApp',
};
</script>

<style scoped lang="scss">
.whatsapp-icon {
  max-width: 60px;
  cursor: pointer;
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 1;
}
</style>
