import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    companyName: 'Freeway Motors SRL',
    companyDescription: 'Venta de repuestos y accesorios Alemanes importados originales para Mercedes Benz y BMW',
    navLinks: [
      {
        section: 'Empresa',
        path: '/',
      },
      {
        section: 'MercadoLibre',
        path: '//listado.mercadolibre.com.ar/_CustId_264210587',
        externalLink: '_blank',
      },
      {
        section: 'Productos',
        path: '/productos',
      },
      {
        section: 'Contacto',
        path: '/contacto',
      },
    ],
  },
  getters: {

  },
  mutations: {

  },
  actions: {

  },
});
