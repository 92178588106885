<template>
  <nav class="main-nav">
    <input id="menu-hamburguer" class="menu-hamburguer" type="checkbox" />
    <label for="menu-hamburguer">
      <div class="menu">
        <span class="hamburguer"></span>
      </div>
    </label>
    <ul class="main-nav-unordered-list">
      <li v-for="(navLink, index) in navLinks" :key="index">
        <router-link :to="navLink.path" :target="navLink.externalLink" class="main-nav__link">{{ navLink.section }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Nav',
  mounted() {
    // main nav variables
    const bodyElement = document.querySelector('body');
    const mainNav = document.querySelector('.menu-hamburguer');
    const mainNavUnorderedList = document.querySelector('.main-nav-unordered-list');
    const mainNavLink = document.getElementsByClassName('main-nav__link');

    // toggle 'main-nav-active' class on 'body' element when main nav is open
    mainNav.onclick = () => {
      bodyElement.classList.toggle('main-nav-active');

      setTimeout(() => {
        mainNavUnorderedList.classList.toggle('main-nav-unordered-list--opacity');
      }, 500);
    };

    // close main nav when a link is clicked
    for (let i = 0; i < mainNavLink.length; i++) {
      const mainNavElement = mainNavLink[i];

      mainNavElement.onclick = () => {
        mainNav.click();
        window.scrollTo(0,0);

        setTimeout(() => {
          if (bodyElement.classList.contains('main-nav-active')) {
            bodyElement.classList.remove('main-nav-active');
          }
        }, 500);
      }
    }
  },
  computed: {
    ...mapState([
      'navLinks'
    ]),
  },
};
</script>

<!-- add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-nav-active .main-nav {
  display: flex;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
}

.main-nav {
  position: fixed;
  z-index: 1;

  .main-nav-unordered-list {
    opacity: 0;
    transition: all 300ms ease-in-out;

    &.main-nav-unordered-list--opacity {
      opacity: 1;
    }
  }

  .menu-hamburguer {
    display: none;

    &:checked {
      & ~ ul {
        display: flex;
        flex-direction: column;
        width: 100vw;
      }

      & ~ label {
        .menu {
          box-shadow: 0 0 0 130vw #fff, 0 0 0 130vh #fff;
        }

        .hamburguer {
          transform: rotate(45deg);

          &::before {
            transform: rotate(90deg);
            top: 0;
          }

          &::after {
            transform: rotate(90deg);
            bottom: 0;
          }
        }
      }
    }
  }

  .menu {
    background: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    position: fixed;
    top: 15px;
    right: 15px;
    cursor: pointer;
    box-shadow: 0 0 0 0 #fff, 0 0 0 0 #fff;
    transition: box-shadow 1.1s cubic-bezier(.19,1,.22,1);

    &:hover {
      box-shadow: none;
    }

    @include grid-media($desktop-neat-grid) {
      &:hover {
        box-shadow: 0 0 0 8px #fff, 0 0 0 8px #fff;
      }
    }

    .hamburguer {
      background: $dark-gray;
      display: block;
      width: 30px;
      height: 2px;
      position: relative;
      top: 30px;
      left: 15px;
      transition: .5s ease-in-out;
      border-radius: 10px;

      &::before,
      &::after {
        background: $dark-gray;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        transition: .5s ease-in-out;
        border-radius: 10px;
      }

      &::before {
        top: -10px;
      }

      &::after {
        bottom: -10px;
      }
    }
  }

  ul {
    width: 100%;
    position: absolute;
    text-align: center;
    display: none;
    transition: .25s .1s cubic-bezier(0, 1.07, 0, 1.02);

    .main-nav__link {
      color: $dark-gray;
      display: block;
      font-size: 30px;
      text-decoration: none;
      padding: 20px 0;

      @include grid-media($tablet-neat-grid) {
        font-size: 47px;
      }

      &:hover {
        color: $light-green;
      }

      &.router-link-exact-active {
        background: linear-gradient(180deg, #242b3a, #1d2430);
        color: white;
        cursor: default;

        &:hover {
          background: linear-gradient(180deg, #242b3a, #1d2430);
          color: white;
          opacity: 1;
        }
      }
    }
  }
}
</style>
